import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import inventoryReducer from "./features/inventory/inventorySlice";
import geoReducer from "./features/geolocation/geoSlice";
import ordersReducer from "./features/orders/ordersSlice";
import ganttReducer from "./features/gantt/ganttSlice";
import notifiationsReducer from "./features/notifications/notificationsSlice";
import leadReducer from "@/features/leads/leadSlice";
import sidebarReducer from "@/features/sidebar/sidebarSlice";
import docsReducer from "@/features/documents/docSlice";

import { customersApi } from "./services/customers";
import { organizationsApi } from "./services/organizations";
import { inventoryApi } from "./services/inventory";
import { geoApi } from "./services/geounit";
import { carriersApi } from "./services/carriers";
import { userApi } from "./services/user";
import { loginApi } from "./services/login";
import { suppliersApi } from "./services/suppliers";
import { gmapApi } from "./services/gmap";
import { leadDetailSlice } from "@/lib/features/leads/ProductsLeaseSlide";
import { taskItemSlice } from "./features/tasks/TaskItemSlice";
import { managementApi } from "./services/management";
import { dashboardLatamApi } from "./services/dashboard-latam";
import { globalErrorSlice } from "./features/error/slice";
import { navbarSlice } from "./features/navbar/navbarSlice";

export const store = configureStore({
  reducer: {
    [managementApi.reducerPath]: managementApi.reducer,
    [dashboardLatamApi.reducerPath]: dashboardLatamApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [inventoryApi.reducerPath]: inventoryApi.reducer,
    [customersApi.reducerPath]: customersApi.reducer,
    [organizationsApi.reducerPath]: organizationsApi.reducer,
    [geoApi.reducerPath]: geoApi.reducer,
    [carriersApi.reducerPath]: carriersApi.reducer,
    [suppliersApi.reducerPath]: suppliersApi.reducer,
    [gmapApi.reducerPath]: gmapApi.reducer,
    inventory: inventoryReducer,
    geo: geoReducer,
    orders: ordersReducer,
    gantt: ganttReducer,
    notifications: notifiationsReducer,
    leads: leadReducer,
    sidebar: sidebarReducer,
    docs: docsReducer,
    leadDetail: leadDetailSlice.reducer,
    tasks: taskItemSlice.reducer,
    globalError: globalErrorSlice.reducer,
    navbar: navbarSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(userApi.middleware)
      .concat(loginApi.middleware)
      .concat(carriersApi.middleware)
      .concat(inventoryApi.middleware)
      .concat(customersApi.middleware)
      .concat(geoApi.middleware)
      .concat(suppliersApi.middleware),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
