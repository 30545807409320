import { API_HOST } from "../client/config";
import { getUserToken } from "../features/auth/services";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GoPage, ListPageResults } from "../dto/requests";
import {
  CommentPayload,
  Product,
  Supplier,
  Comment,
  SupplierProduct,
  Branch,
} from "../dto/inventory";
import {
  MetabaseDashboardInfo,
  MySpaceChartData,
  MySpaceKpiData,
  MySpaceKpiDateRange,
} from "../dto/dashboard";
import { NonOperationalDayPayload, Operability } from "../dto/orders";
import {
  ServiceArea,
  SupplierFee,
  SupplierFeePayload,
  SupplierTag,
} from "../dto/leads";
import { PAGE_SIZE } from "./config";
import { inventoryApi } from "./inventory";
import { firstLetterCapitalize } from "../utils";
import { RosMessage } from "../dto/messages";
export const suppliersApi = inventoryApi.injectEndpoints({
  endpoints: (builder) => ({
    getSuppliers: builder.query<ListPageResults<Supplier>, GoPage | null>({
      providesTags: ["Suppliers"],
      query: (goPage) => {
        const params = new URLSearchParams();
        if (goPage) {
          if (goPage.page_size) params.set("page_size", `${goPage.page_size}`);
          if (goPage.cursor) params.append("cursor", goPage.cursor);
          if (goPage.isPrevious) params.append("previous", "");
          if (goPage.kind) params.append(goPage.kind, "true");
          if (goPage.kind) params.append("kind", goPage.kind);
          if (goPage.search) params.append("search", goPage.search);
          if (goPage.filters) {
            Object.keys(goPage.filters).forEach((key) => {
              params.append(key, goPage.filters[key]);
            });
          }
        }
        return `suppliers?${params.toString()}`;
      },
      transformResponse: (response: any) => {
        return {
          ...response,
          results: response.results.map((product: Product) => {
            return {
              ...product,
              name: firstLetterCapitalize(product.name),
            };
          }),
        };
      },
    }),

    deleteSupplier: builder.mutation<void, number>({
      invalidatesTags: ["Suppliers"],
      query: (id) => ({
        url: `suppliers/${id}`,
        method: "DELETE",
      }),
    }),

    createSupplier: builder.mutation({
      invalidatesTags: ["Suppliers"],
      query: (supplier) => {
        return {
          method: "POST",
          url: "suppliers",
          body: supplier,
        };
      },
    }),

    updateSupplier: builder.mutation<
      Supplier,
      Partial<Supplier> & Pick<Supplier, "id">
    >({
      query: ({ id, ...data }) => ({
        url: `suppliers/${id}`,
        method: "PUT",
        body: data,
      }),
    }),

    getSupplierProducts: builder.query<
      ListPageResults<Product>,
      { id: number; category_id?: number }
    >({
      providesTags: ["SupplierProducts"],
      query: (data) => {
        const params = new URLSearchParams();
        params.set("page_size", "999");
        if (data.category_id) params.set("category_ids", `${data.category_id}`);
        return `supplier-products-by-supplier/${data.id}?${params.toString()}`;
      },
      transformResponse: (response: any) => {
        return {
          ...response,
          results: response.results.map((product: Product) => {
            return {
              ...product,
              searchName: `${product.name} (${product.supplier_internal_id})`,
            };
          }),
        };
      },
    }),

    getMetabaseDashboard: builder.query<MetabaseDashboardInfo, void>({
      query: () => {
        return "metabase/dashboard";
      },
    }),

    getOperability: builder.query<Operability[], GoPage>({
      providesTags: ["Operability"],
      query: (goPage) => {
        const params = new URLSearchParams();
        if (goPage) {
          if (goPage.filters) {
            Object.keys(goPage.filters).forEach((key) => {
              params.append(key, goPage.filters[key]);
            });
          }
        }
        return `operability?${params.toString()}`;
      },
    }),

    deleteNonOperationalDay: builder.mutation<void, number>({
      invalidatesTags: ["Operability"],
      query: (id) => ({
        url: `non-operational-days/${id}`,
        method: "DELETE",
      }),
    }),

    createNonOperationalDay: builder.mutation<void, NonOperationalDayPayload>({
      invalidatesTags: ["Operability"],
      query: (payload) => ({
        url: `operability`,
        method: "POST",
        body: payload,
      }),
    }),

    updateNonOperationalDay: builder.mutation<
      void,
      { id: number; payload: NonOperationalDayPayload }
    >({
      invalidatesTags: ["Operability"],
      query: ({ id, payload }) => ({
        url: `non-operational-days/${id}`,
        method: "PUT",
        body: payload,
      }),
    }),

    createComment: builder.mutation<
      void,
      { id: number; kind: string; data: CommentPayload }
    >({
      invalidatesTags: ["Comments", "Operability"],
      query: ({ id, kind, data }) => ({
        url: `${API_HOST}/suppliers/v101/${kind}/${id}/comments`,
        method: "POST",
        body: data,
      }),
    }),

    getComments: builder.query<
      ListPageResults<Comment>,
      { id: number; kind: string }
    >({
      providesTags: ["Comments"],
      query: ({ id, kind }) =>
        `${API_HOST}/suppliers/v101/${kind}/${id}/comments`,
    }),

    updateComment: builder.mutation<void, { id: number; data: CommentPayload }>(
      {
        invalidatesTags: ["Comments", "Operability"],
        query: ({ id, data }) => ({
          url: `${API_HOST}/suppliers/v101/comments/${id}`,
          method: "PATCH",
          body: data,
        }),
      }
    ),

    deleteComment: builder.mutation<void, { id: number }>({
      invalidatesTags: ["Comments", "Operability"],
      query: ({ id }) => ({
        url: `${API_HOST}/suppliers/v101/comments/${id}`,
        method: "DELETE",
      }),
    }),
    getSuppliersFeeList: builder.query<ListPageResults<SupplierFee>, GoPage>({
      providesTags: ["SuppliersFee"],
      query: (goPage) => {
        const params = new URLSearchParams();

        params.append("page_size", PAGE_SIZE.toString());

        if (goPage) {
          if (goPage.cursor) params.append("cursor", goPage.cursor);
          if (goPage.isPrevious) params.append("previous", "");
          if (goPage.search) params.append("search", goPage.search);
          if (goPage.kind) params.append("kind", goPage.kind);
          if (goPage.filters) {
            Object.keys(goPage.filters).forEach((key) => {
              params.append(key, goPage.filters[key]);
            });
          }
        }
        return `${API_HOST}/suppliers/v102/category-prices?${params.toString()}`;
      },
    }),
    createSupplierFee: builder.mutation<SupplierFee, SupplierFeePayload>({
      invalidatesTags: ["SuppliersFee"],
      query: (payload) => ({
        url: `${API_HOST}/suppliers/v102/category-prices`,
        method: "POST",
        body: payload,
      }),
    }),
    deleteSupplierFee: builder.mutation<void, number>({
      invalidatesTags: ["SuppliersFee"],
      query: (id) => ({
        url: `${API_HOST}/suppliers/v102/category-prices/${id}`,
        method: "DELETE",
      }),
    }),
    updateSupplierFee: builder.mutation<
      SupplierFee,
      { id: number; payload: SupplierFeePayload }
    >({
      invalidatesTags: ["SuppliersFee"],
      query: ({ id, payload }) => ({
        url: `${API_HOST}/suppliers/v102/category-prices/${id}`,
        method: "PUT",
        body: payload,
      }),
    }),

    getSupplierTags: builder.query<ListPageResults<SupplierTag>, void>({
      query: () => {
        return "tags?page_size=999";
      },
    }),
    createSimpleSupplierProduct: builder.mutation<
      SupplierProduct,
      SupplierProduct
    >({
      invalidatesTags: ["SupplierProducts", "LeadMovements", "LeadDetails"],
      query: (product) => ({
        url: `${API_HOST}/suppliers/v102/minimum-supplier-products`,
        method: "POST",
        body: product,
      }),
    }),
    getBranches: builder.query<ListPageResults<Branch>, GoPage | null>({
      providesTags: ["Branches"],
      query: (goPage) => {
        const params = new URLSearchParams();

        params.append("page_size", PAGE_SIZE.toString());

        if (goPage) {
          if (goPage.cursor) params.append("cursor", goPage.cursor);
          if (goPage.isPrevious) params.append("previous", "");
          if (goPage.search) params.append("search", goPage.search);
          if (goPage.kind) params.append("kind", goPage.kind);
          if (goPage.filters) {
            Object.keys(goPage.filters).forEach((key) => {
              params.append(key, goPage.filters[key]);
            });
          }
        }
        return `${API_HOST}/suppliers/v102/branches?${params.toString()}`;
      },
    }),
    createBranch: builder.mutation<Branch, Branch>({
      invalidatesTags: ["Branches"],
      query: (branch) => {
        return {
          method: "POST",
          url: `${API_HOST}/suppliers/v102/branches`,
          body: branch,
        };
      },
    }),
    updateBranch: builder.mutation<Branch, { id: number; payload: Branch }>({
      invalidatesTags: ["Branches"],
      query: ({ id, payload }) => ({
        url: `${API_HOST}/suppliers/v102/branches/${id}`,
        method: "PUT",
        body: payload,
      }),
    }),
    deleteBranch: builder.mutation<void, number>({
      invalidatesTags: ["Branches"],
      query: (id) => ({
        url: `${API_HOST}/suppliers/v102/branches/${id}`,
        method: "DELETE",
      }),
    }),
    getServiceAreas: builder.query<ServiceArea[], void>({
      query: () => {
        return `${API_HOST}/suppliers/v102/service-areas`;
      },
    }),
    getMySpaceChartData: builder.query<
      MySpaceChartData,
      {
        kind: string;
        supplier_user_id: number;
      }
    >({
      query: (payload) => {
        return `${API_HOST}/suppliers/v102/my-space-chart?kind=${payload.kind}&supplier_user_id=${payload.supplier_user_id}`;
      },
    }),
    getMySpaceKpisData: builder.query<
      MySpaceKpiData,
      {
        supplier_user_id: number;
        date: string;
      }
    >({
      query: (payload) => {
        return `${API_HOST}/suppliers/v102/my-space-kpis?supplier_user_id=${payload.supplier_user_id}&date=${payload.date}`;
      },
    }),
    getMySpaceKpisDataRange: builder.query<MySpaceKpiDateRange, number>({
      query: (supplier_user_id) => {
        return `${API_HOST}/suppliers/v102/my-space-kpis-date-range?supplier_user_id=${supplier_user_id}`;
      },
    }),
    getRosMessages: builder.query<ListPageResults<RosMessage>, GoPage>({
      providesTags: ["RosMessages"],
      query: (goPage) => {
        const params = new URLSearchParams();
        if (goPage && goPage.page_number)
          params.append("page_number", `${goPage.page_number}`);
        if (goPage && goPage.filters) {
          Object.keys(goPage.filters).forEach((key) => {
            params.append(key, goPage.filters[key]);
          });
        }
        return `${API_HOST}/suppliers/v102/ros-messages?${params.toString()}`;
      },
    }),
    createRosMessage: builder.mutation<RosMessage, string>({
      query: (message) => {
        return {
          url: `${API_HOST}/suppliers/v102/ros-messages`,
          method: "POST",
          body: {
            message,
          },
        };
      },
    }),
    clearRosMessages: builder.mutation<void, void>({
      invalidatesTags: ["RosMessages"],
      query: () => {
        return {
          url: `${API_HOST}/suppliers/v102/ros-messages/clear-messages`,
          method: "DELETE",
        };
      },
    }),
    refreshRosMessages: builder.mutation<void, void>({
      query: () => {
        return {
          url: `${API_HOST}/suppliers/v102/ros-messages/update-context?use_cache=false`,
          method: "POST",
        };
      },
    }),
  }),
});

export const {
  useGetSuppliersQuery,
  useDeleteSupplierMutation,
  useGetSupplierProductsQuery,
  useCreateSupplierMutation,
  useUpdateSupplierMutation,
  useGetMetabaseDashboardQuery,
  useGetOperabilityQuery,
  useDeleteNonOperationalDayMutation,
  useCreateNonOperationalDayMutation,
  useUpdateNonOperationalDayMutation,
  useCreateCommentMutation,
  useGetCommentsQuery,
  useUpdateCommentMutation,
  useDeleteCommentMutation,
  useGetSuppliersFeeListQuery,
  useCreateSupplierFeeMutation,
  useDeleteSupplierFeeMutation,
  useUpdateSupplierFeeMutation,
  useGetSupplierTagsQuery,
  useCreateSimpleSupplierProductMutation,
  useGetBranchesQuery,
  useCreateBranchMutation,
  useUpdateBranchMutation,
  useDeleteBranchMutation,
  useGetServiceAreasQuery,
  useGetMySpaceChartDataQuery,
  useGetMySpaceKpisDataQuery,
  useGetMySpaceKpisDataRangeQuery,
  useGetRosMessagesQuery,
  useCreateRosMessageMutation,
  useClearRosMessagesMutation,
  useRefreshRosMessagesMutation,
} = suppliersApi;
